.container {
    display: flex;
    height: 100%;
    width: 100%;
    padding: 0;
    margin: 0;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.panel-left {
    visibility: hidden;
    height: 100%;
    flex: 1;
    background-color: #00ADEE;
    background-size: cover;
    background-position: bottom;
    display: flex;
    flex-direction: column;
    align-items: center;
}
@media all and (min-width: 768px) {
    .panel-left {
        visibility: visible;
    }
}
.panel-right {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    min-width: 300px;
    background-color: #fff;
    justify-content: center;
    align-items: center;
    transition: all 4s ease;
}
@media all and (min-width: 920px) {
    .panel-right {
        width: 50%;
        max-width: 600px;
    }
}
@media all and (min-width: 1200px) {
    .panel-right {
        width: 40%;
        max-width: 600px;
    }
}