.interactive {
    cursor: pointer;
}

.interactive:hover {
    transform: translateY(-2px);
    transition: all .2s ease;
}


/* 
    PEDESTAL STATES
*/

/* VACANT */
.pedestal.vacant [id^="Pin"] {
    fill: #003C73 !important;
}
.pedestal.vacant [id^="Icon"] {
    display: inline !important;
}
.pedestal.vacant [id^="Warning"] {
    display: none !important;
}
.pedestal.vacant [id^="Offline"] {
    display: none !important;
}



/* ASSIGNED */
.pedestal.assigned [id^="Pin"] {
    fill: #6cb33f !important;
}
.pedestal.assigned [id^="Icon"] {
    display: inline !important;
}
.pedestal.assigned [id^="Warning"] {
    display: none !important;
}
.pedestal.assigned [id^="Offline"] {
    display: none !important;
}


/* RESERVED */
.pedestal.reserved [id^="Pin"] {
    fill: #ecab00 !important;
}
.pedestal.reserved [id^="Icon"] {
    display: inline !important;
}
.pedestal.reserved [id^="Warning"] {
    display: none !important;
}
.pedestal.reserved [id^="Offline"] {
    display: none !important;
}



/* FAULT */
.pedestal.fault [id^="Pin"] {
    fill: #ff0000 !important;
}
.pedestal.fault [id^="Icon"] {
    display: none !important;
}
.pedestal.fault [id^="Warning"] {
    display: inline !important;
}
.pedestal.fault [id^="Offline"] {
    display: none !important;
}

/* OFFLINE */
.pedestal.offline [id^="Pin"] {
    fill: #81868b !important;
}
.pedestal.offline [id^="Icon"] {
    display: none !important;
}
.pedestal.offline [id^="Warning"] {
    display: none !important;
}
.pedestal.offline [id^="Offline"] {
    display: inline !important;
}

.pedestal.hidden {
    display: none !important;
}


.pedestal .pedestal-id {
    font: 900 7px "Roboto";
    fill: #ffffff;
    stroke:#000  ;
    stroke-linecap:round;
    stroke-linejoin:round;
    stroke-width:.4px;
    opacity: 0;
    visibility: hidden;
    transition: all .3s ease;
}

.pedestal:hover > .pedestal-id, .pedestal.show-numbers > .pedestal-id {
    opacity: 1;
    visibility: inherit;
    transition: all .3s ease;
}



.pedestal [id^="Icon"]{
    opacity: .9;
}

.pedestal.show-numbers [id^="Icon"]{
    opacity: .7;
}




.berth.empty path[id^="Boat"] {
    fill: transparent !important;
    visibility: hidden;
}


.berth.hidden {
    display: none !important;
}